import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How I Automated My github Code to Gatsby Posts",
  "author": "tzookb",
  "type": "post",
  "date": "2020-09-01T13:50:08.000Z",
  "url": "/how-i-automated-my-github-code-to-gatsby-posts",
  "featuredImage": "./thumb.jpg",
  "categories": ["gatsby", "automation", "react", "javascript"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">{`My previous situation`}</li>
      <li parentName="ul">{`Goal`}</li>
      <li parentName="ul">{`Action`}</li>
      <li parentName="ul">{`Outcome`}</li>
    </ul>
    <h2>{`My previous situation`}</h2>
    <p>{`As I moved my blog to Gatsby, I started adding more content to my site.
So "normal" blog posts just like this one, its fine to manually upload as I need to write it down first.
The problem was that I was solving algorithm exercises either on LeetCode, HackerRank or other.
I would upload the code my github repo and then I'll need to go to my blog and write a blog post.
The rate of me solving problems was much faster of me uploading the blog content.`}</p>
    <h2>{`Goal`}</h2>
    <p>{`I would like to be able to upload my algorithm solutions, add some readme doc and then it will automatically create a new blog post on my blog.`}</p>
    <h2>{`Action`}</h2>
    <p>{`I would first need to decide on a "template" for my readme page, so I'll know how to build the blog post on my site.
After that I altered my Gatsby site, to load my Github repo files. It will look for readme file with a specific template and if
it find those, it will create a blog post with that content.`}</p>
    <h2>{`Outcome`}</h2>
    <p>{`Everytime I solve a new exercise and want to have a blog post for it, I just create the readme file.
Push the code and post gets created on my site.`}</p>
    <h1>{`Actions Steps on Gatsby`}</h1>
    <ul>
      <li parentName="ul">{`add this config to `}<inlineCode parentName="li">{`gatsby-config.js`}</inlineCode></li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`{
  resolve: \`gatsby-source-git\`,
  options: {
    name: \`challenges\`,
    remote: \`https://github.com/tzookb/programming-challenges.git\`,
    patterns: [\`exercises/**/*.md\`]
  }
},
`}</code></pre>
    <ul>
      <li parentName="ul">{`add product creation in `}<inlineCode parentName="li">{`gatsby-node.js`}</inlineCode></li>
      <li parentName="ul">{`I wont share all the page creation as its a bit big`}</li>
      <li parentName="ul">{`but the relevant Graphql query is this:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`  const result = await graphql(\`
    {
      allFile(
        filter: { sourceInstanceName: { eq: "challenges" } },
        limit: 10000,
        sort: {order: ASC, fields: [childMdx___frontmatter___date]}
      ) {
        edges {
          node {
            relativePath
            childMarkdownRemark {
              frontmatter {
                title
                url
                desc
                date
                source
              }
              html
            }
          }
        }
      }
  \`)
`}</code></pre>
    <p>{`I look up all the files (only readmes, from config)
and take the details I need for creating the post page.`}</p>
    <p>{`just go to the home page and look up any LeetCode or HackerRank exercises :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      